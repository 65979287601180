<template>
	<div class="suppierDetail">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-loading='loading' style="margin-bottom: 150px;">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content">
					<el-form-item label="活动时间：" required>
						<el-form-item prop="StartTime" class="el-form-date-pick">
							<el-date-picker :disabled="currentActivityState==2||currentActivityState==1" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 v-model="ruleForm.StartTime" type="datetime" placeholder="开始时间" :picker-options='maxOptions'>
							</el-date-picker>
						</el-form-item>
						<span style="margin:0 5px;">~</span>
						<el-form-item prop="EndTime" class="el-form-date-pick">
							<el-date-picker :disabled="currentActivityState==2" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 v-model="ruleForm.EndTime" type="datetime" placeholder="结束时间" :picker-options='minOptions'>
							</el-date-picker>
						</el-form-item>
					</el-form-item>

					<el-form-item label="兑换商品：" prop="Type">
						<div class="radio">
							<el-radio :disabled="currentActivityState!=3" v-model="ruleForm.Type" @change="handleRadioPro" :label="0">商品</el-radio>
							<span v-if="ruleForm.Type==0&&currentActivityState==3" style="color:#409EFF;cursor: pointer;" @click="handleSelectPro">选择商品</span>
						</div>
						<!-- list -->
						<el-form-item v-if="ruleForm.Type==0&&ruleForm.ProductSpecList&&ruleForm.ProductSpecList.length">
							<div class="dialog-name-content">
								<img :src='imgUrl+ruleForm.ImgUrl' />
								<div class="right">
									<div class="name">
										<span style="color:#F56C6C" v-if="ruleForm.ProductType =='组合商品'">[组合]</span>
										{{ruleForm.ProductName}}
									</div>
								</div>
							</div>
							<el-table border :data="ruleForm.ProductSpecList" style="width: 100%">
								<el-table-column :key="0" label="规格">
									<template slot-scope="scope">
										<div v-if="scope.row.IsDefault">
											默认规格
										</div>
										<div v-else>
											{{scope.row.SpecValue}}
											<span>;</span>
											{{scope.row.SpecValue2}}
										</div>

									</template>
								</el-table-column>
								<el-table-column :key="1" prop="Price" label="价格"></el-table-column>
								<el-table-column :key="2" prop="Stock" label="库存"></el-table-column>
								<el-table-column :key="3" label="可兑换总量">
									<template slot-scope="scope">
										<el-input :disabled="currentActivityState==2" style="width:100px" v-model="scope.row.ExchangeCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
										 onblur="this.v();if ( value==0||value=='-'){value =''}" />
									</template>
								</el-table-column>
								<el-table-column :key="4" label="兑换价">
									<template slot-scope="scope">
										<el-input :disabled="currentActivityState!=3" style="width:100px" v-model="scope.row.PointCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
										 onblur="this.v();if ( value==0||value=='-'){value =''}" />
										积分+
										<el-input :disabled="currentActivityState!=3" @keyup.native="PointPricekeyU" style="width:100px" v-model="scope.row.PointPrice" />
										元
									</template>
								</el-table-column>
							</el-table>
							<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
							 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]"
							 :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
							</el-pagination>
						</el-form-item>

						<div class="radio">
							<el-radio :disabled="currentActivityState!=3" v-model="ruleForm.Type" @change="handleRadioPro" :label="1">优惠券</el-radio>
							<!-- <el-radio :disabled="currentActivityState!=3" v-model="ruleForm.Type" @change="handleRadioCoupon"  :label="1"></el-radio> -->
							<span v-if="ruleForm.Type==1&&currentActivityState==3" style="color:#409EFF;cursor: pointer;" @click="handleSelectCoupon">选择优惠券</span>
						</div>

						<!-- 优惠券列表 -->
						<el-form-item v-if="ruleForm.Type==1&&ruleForm.CouponList&&ruleForm.CouponList.length!=0">
							<el-table border :data="ruleForm.CouponList" style="width: 100%">
								<el-table-column :key="5" prop="CouponName" label="优惠券"></el-table-column>
								<el-table-column :key="6" prop="UseTypeValue" label="类型"></el-table-column>
								<el-table-column :key="7" prop="CouponContent" label="优惠内容"></el-table-column>
								<el-table-column :key="8" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
								<el-table-column :key="9" label="可兑换总量">
									<template slot-scope="scope">
										<el-input @input="changeforceUpdate($event)" :disabled="currentActivityState==2" style="width:100px" v-model="scope.row.ExchangeCount"
										 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0){value =''}"
										 onblur="this.v();if ( value==0||value=='-'){value =''}" />
									</template>
								</el-table-column>
								<el-table-column :key="10" label="兑换价">
									<template slot-scope="scope">
										<el-input @input="changeforceUpdate($event)" :disabled="currentActivityState!=3" style="width:100px;margin-right:5px;"
										 v-model="scope.row.PointCount" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0){value =''}"
										 onblur="this.v();if ( value==0||value=='-'){value =''}" />积分
									</template>
								</el-table-column>
							</el-table>
							<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
							 @current-change="handleSizeChange" :current-page="page.current" :page-sizes="[5,10, 20, 30, 40, 50]" :page-size="page.size"
							 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
							</el-pagination>
						</el-form-item>
					</el-form-item>


					<!-- 限兑数量 -->
					<el-form-item label="限兑数量：" required>
						<el-radio-group :disabled="currentActivityState==2" v-model="limitRadio" style='margin-top:10px;' @change="changeLimitRadio">
							<el-radio :label="0" class="radio-group">不限制</el-radio>
							<el-radio :label="1" class="radio-group" style='margin-top:10px;'>
								限制
								<el-form-item prop="Limit" style="margin:0 10px;" ref="editIntegralLimitValue" class="el-form-date-pick">
									<el-input style="width:200px;" :disabled="limitRadio==0||currentActivityState==2" v-model="ruleForm.Limit"
									 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									 onblur="this.v();if ( value==0||value=='-'){value =''}"></el-input>件/人
								</el-form-item>
							</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<!-- 选择商品 -->
		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
			<select-produce :propData="propData" @getSelection='getProSelect' :visible.sync="selectProDialog" v-if="selectProDialog"></select-produce>
		</el-dialog>

		<!-- 选择优惠券 -->
		<el-dialog title="选择优惠券" :visible.sync="selectCouponDialog" width="960px" class="dialog data-dialog">
			<select-coupon :couponCheckedId="couponCheckedId" @getSelectCoupon='getCouponSelect' :visible.sync="selectCouponDialog"
			 v-if="selectCouponDialog"></select-coupon>
		</el-dialog>
		<!-- 保存商品冲突 -->
		<el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="conflictGoodsShow" width="1000px" class="dialog data-dialog">
			<div>
				<el-table border :data="conflictGoodsList" style="max-height:450px;overflow: auto;">
					<el-table-column label="商品">
						<template slot-scope="scope">
							<div class="dialog-name-content">
								<img :src='imgUrl+scope.row.ImgUrl' />
								<div class="right">
									<div class="name">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Remark" label="冲突信息"></el-table-column>
				</el-table>
			</div>
			<div style="text-align:center;margin-top:20px;">
				<el-button class="button" style="width:200px" @click="conflictGoodsShow=false" type="primary">确定</el-button>
			</div>
		</el-dialog>
		<div class="footer" v-if="currentActivityState!=2">
			<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
		</div>
		<div class="footer" v-if="currentActivityState==2">
			<el-button style="width:240px;" @click="suppilerBack">返回</el-button>
		</div>
	</div>
</template>

<script>
	import apiList from '@/api/other'
	import config from '@/config/index'
	import selectProduce from './selectProDialog'
	import selectCoupon from '@/components/SelectCouponDialog'
	export default {
		components: {
			selectCoupon,
			selectProduce
		},
		data() {
			var checkLimitValue = (rule, value, callback) => {
				if (this.limitRadio == 1 && (!value)) {
					return callback(new Error('请输入限兑数量'));
				} else {
					return callback();
				}
			};
			var checkNone = (rule, value, callback) => {
				return callback();
			}
			return {
				imgUrl: config.IMG_BASE,
				limitRadio: 0,
				loading: false,
				currentQueryId: '',
				currentQueryCopyId: '',
				ruleForm: {
					StartTime: '',
					EndTime: '',
					Limit: '',
					Type: 0,
					ProductName: '',
					ImgUrl: '',
					ProductType: 0,
					ProductSpecList: [],
					CouponList: [],
					Delstatus: false
				},
				selectProDialog: false,
				selectCouponDialog: false,
				page: {
					total: 0,
					current: 10,
					size: 5
				},
				currentCount: '',
				rules: {
					StartTime: [{
						required: true,
						message: '请选择开始时间',
						trigger: 'change'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束时间',
						trigger: 'change'
					}],
					Limit: [{
						trigger: 'blur',
						validator: checkLimitValue
					}],
					none: [{
						trigger: 'blur',
						validator: checkNone
					}]
				},
				//选择商品组件prop
				propData: {
					ProductType: 0,
					StartTime: '',
					EndTime: '',
					ProductCheckId: '',
					currentQueryId: ''
				},
				couponCheckedId: 0,
				conflictGoodsShow: false,
				currentActivityState: 3,
				conflictGoodsList: [{
					Name: '',
					ImgUrl: '',
					Remarks: ''
				}],

			}
		},
		created() {
			this.currentQueryId = this.$route.query.id
			this.currentQueryCopyId = this.$route.query.copyId
			this.currentActivityState = this.$route.query.ActivityState

			if (this.currentQueryId || this.currentQueryCopyId) {
				this.getData()
			}
		},
		watch: {},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		methods: {
			changeforceUpdate(e) {
				this.$forceUpdate()
			},
			PointPricekeyU(e) {
				e.target.value = e.target.value.replace(/[^\d.]/g, ""); //仅保留数字和"."
				e.target.value = e.target.value.replace(/\.{2,}/g, "."); //两个连续的"."仅保留第一个"."
				e.target.value = e.target.value.replace(".", "$#*").replace(/\./g, '').replace('$#*', '.'); //去除其他"."
				e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); //限制只能输入两个小数
				if (e.target.value.indexOf(".") < 0 && e.target.value != "") {
					e.target.value = parseFloat(e.target.value);
				}

			},
			handleSelectPro() {
				if (!this.ruleForm.StartTime||this.ruleForm.StartTime==''||!this.ruleForm.EndTime||this.ruleForm.EndTime=='') {
					this.$message({showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					});
				} else {
					this.propData = {
						StartTime: this.ruleForm.StartTime,
						EndTime: this.ruleForm.EndTime,
						ProductCheckId: this.ruleForm.ProductSpecList.length == 0 ? null : this.ruleForm.ProductSpecList[0].ProductId,
						currentQueryId: this.currentQueryId || this.currentQueryCopyId
					}
					this.selectProDialog = true
				}
			},
			//  
			handleSelectCoupon() {
				this.selectCouponDialog = true
			},
			async getData() {
				this.loading = true
				//复制：currentQueryCopyId，编辑：currentQueryId
				let id = this.currentQueryId == undefined ? this.currentQueryCopyId : this.currentQueryId
				let result = await apiList.activityPointExchangeInfo({
					Id: id,
					IsCopy:this.currentQueryCopyId? true : false
				})
				this.ruleForm = result.Result
				this.limitRadio = result.Result.Limit == 0 ? 0 : 1

				if (this.currentQueryCopyId) {
					this.ruleForm.StartTime = ''
					this.ruleForm.EndTime = ''
					if (this.ruleForm.Delstatus) {
						if (this.ruleForm.Type == 1) {
							this.ruleForm.CouponList = []
						} else {
							this.ruleForm.ProductName = ''
							this.ruleForm.ImgUrl = ''
							this.ruleForm.ProductSpecList = []
						}
					}
				}
				this.loading = false
			},
			changeLimitRadio(e) {
				if (e == 0) {
					this.ruleForm.Limit = ''
					this.$refs['editIntegralLimitValue'].clearValidate()
				}
			},
			//获取选择的商品
			getProSelect(data) {
				if (Object.keys(data).length > 0) {
					data.ProductSpecList.forEach((t) => {
						t.ExchangeCount = ''
						t.PointCount = ''
						t.PointPrice = ''
					})
					this.ruleForm.ProductSpecList = data.ProductSpecList
					this.ruleForm.ProductName = data.Name
					this.ruleForm.ProductType = data.ProductTypeValue
					this.ruleForm.ImgUrl = data.ImgUrl
					this.selectProDialog = false
				} else {
					this.selectProDialog = false
				}

			},
			//获取选择的优惠券列表
			getCouponSelect(data) {
				if(!data.Id){
					this.selectCouponDialog = false
					return false
				}
				this.couponCheckedId = data.Id
				this.ruleForm.CouponList = []
				this.ruleForm.CouponList.push(data)
				this.ruleForm.CouponList.map(t => {
						t.CouponId = t.Id
				})
				this.selectCouponDialog = false
				this.$forceUpdate()
			},
			// 取消
			suppilerCansel() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？', '提示', {
					confirmButtonText: '确认离开',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/Customer/integralMall/index'
					});
				})
			},
			suppilerBack() {
				this.$router.push({
					path: '/Customer/integralMall/index'
				});
			},
			handleRadioPro(e) {
				this.conflictGoodsList = []
				this.couponCheckedId = 0
				this.ruleForm.CouponList = []

				this.propData.ProductCheckId = 0
				this.ruleForm.ProductSpecList = []
				this.$forceUpdate()
			},
			// 保存，处理数据
			async saveSupiler(ruleForm) {
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						if (this.ruleForm.Type) {
							this.ruleForm.ProductSpecList = []
							this.ruleForm.ProductName = ''
						} else {
							this.ruleForm.CouponList = []
						}
						this.loading = true
						try {
							let data = {
								Id: this.currentQueryId,
								Type: this.ruleForm.Type,
								ProductType: this.ruleForm.ProductType,
								ProductName: this.ruleForm.ProductName,
								StartTime: this.ruleForm.StartTime,
								EndTime: this.ruleForm.EndTime,
								ProductSpecList: this.ruleForm.ProductSpecList,
								CouponList: this.ruleForm.CouponList,
								Limit: this.ruleForm.Limit
							}

							let result = await apiList.activityPointExchangeSave(data);

							if (result.IsSuccess) {
								this.$router.push({
									path: '/Customer/integralMall/index'
								});
								this.$message({showClose: true,
									type: 'success',
									message: '操作成功'
								});
							} else {
								this.conflictGoodsList = result.Result
								if (result.Result.length != 0) {
									if (!this.ruleForm.Type) {
										this.conflictGoodsList.forEach((t) => {
											t.Name = this.ruleForm.ProductName
											t.ImgUrl = this.ruleForm.ImgUrl
										})
									}
									this.conflictGoodsShow = true
								}
							}
							this.loading = true
						} catch (error) {
							console.log(error);
							this.loading = false
						} finally {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.suppierDetail {
		.remarks {
			color: #bbb;
			font-size: 14px;
		}

		.parent-box {
			position: relative;

			.close-bosx {
				position: absolute;
				right: -5px;
				top: -13px;
				font-size: 18px;
			}
		}

		.markRed {
			position: absolute;
			left: -94px;
			top: 5px;
			color: red;
			font-size: 18px;
		}
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}
	}

	.table-container {
		position: relative;
		overflow: hidden;
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}

	.radio-group {
		display: block;
	}

	.dialog-footer {
		.button {
			width: 150px;
		}

		text-align: center;
		margin-top: 20px;
	}

	//隐藏此刻
	::v-deep .el-button--text {
		display: none
	}

	.footer {
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
		box-shadow: 13px 1px 6px #999;
		z-index: 999;
	}
</style>
